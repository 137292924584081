import { ProjectSettings } from '@valiot/settings'
import packageJson from '../package.json'
import Logo from 'components/Logo/Logo'
const settings: ProjectSettings = {
  version: packageJson.version,
  copilotEnabled: false,
  title: 'Prolamsa - Valiot',
  browserMonitoring: {
    enabled: true,
    serviceName: import.meta.env.VITE_API_URL.includes('dev') ? 'prolamsa-dev-front' : 'prolamsa-prod-front',
    apiKey: import.meta.env.VITE_BROWSER_MONITORING_API_KEY,
    advancedNetworkCapture: !import.meta.env.VITE_API_URL.includes('dev'),
    consoleCapture: !import.meta.env.VITE_API_URL.includes('dev'),
  },
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  customerLogo: {
    src: <Logo />,
  },
  analytics: {
    enabled: false,
    // siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'es',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  tenancy: {
    enabled: true,
  },
  alerts: {
    byRoles: {
      'Valiot Support': {
        codes: 'all',
      },
      ['Planeación']: {
        codes: ['LOTS_SOON_TO_EXPIRE', 'EXPIRED_LOTS', 'LOW_REQ_QTY_MATERIALS', 'PLANS_WITH_INSUFFICIENT_MATERIALS'],
      },
      'TI SAP': {
        // currently sent as notifications
        codes: [],
      },
    },
  },
  sidebarDefault: 'expanded',
  home: 'sequence/timeline-sequence',
  routes: [],
  dropdowns: [],
  sections: [
    {
      path: 'sequence',
      title: 'Secuencia',
      directory: 'sequence',
      routes: [
        {
          path: 'timeline-sequence',
          text: 'Cronograma general',
          icon: 'stream',
          iconSource: 'fas',
          component: 'TimelineSequence',
          sidebarDefault: 'expanded',
        },
        {
          path: 'timeline-list',
          text: 'Secuencia detallada',
          icon: 'tasks',
          iconSource: 'fas',
          component: 'ListSequence',
          sidebarDefault: 'expanded',
        },
      ],
      dropdowns: [
        {
          path: 'plannerOne',
          text: 'Planeador 1',
          directory: 'plannerOne',
          iconSource: 'fas',
          icon: 'calendar-day',
          routes: [
            {
              path: 'timeline-sequence',
              text: 'Cronograma',
              icon: 'stream',
              component: 'TimelineSequence',
              hidden: false,
              permissions: 'all',
            },
            {
              path: 'timeline-list',
              text: 'Lista detallada',
              icon: 'tasks',
              component: 'ListSequence',
              hidden: false,
              permissions: 'all',
            },
          ],
        },
        {
          path: 'plannerTwo',
          text: 'Planeador 2',
          directory: 'plannerTwo',
          iconSource: 'fas',
          icon: 'calendar-day',
          routes: [
            {
              path: 'requirements',
              text: 'Cronograma',
              icon: 'stream',
              component: 'TimelineSequence',
              hidden: false,
              permissions: 'all',
            },
            {
              path: 'orders',
              text: 'Lista detallada',
              icon: 'tasks',
              component: 'ListSequence',
              hidden: false,
              permissions: 'all',
            },
          ],
        },
        {
          path: 'plannerThree',
          text: 'Planeador 3',
          directory: 'plannerThree',
          iconSource: 'fas',
          icon: 'calendar-day',
          routes: [
            {
              path: 'timeline-sequence',
              text: 'Cronograma',
              icon: 'stream',
              component: 'TimelineSequence',
              hidden: false,
              permissions: 'all',
            },
            {
              path: 'timeline-list',
              text: 'Lista detallada',
              icon: 'tasks',
              component: 'ListSequence',
              hidden: false,
              permissions: 'all',
            },
          ],
        },

        {
          path: 'robots',
          text: 'Robots',
          directory: 'robots',
          iconSource: 'fas',
          icon: 'calendar-day',
          routes: [
            // TODO: this timeline is hidden until the overlap issue is solved.
            // {
            //   path: 'timeline-sequence',
            //   text: 'Cronograma',
            //   icon: 'stream',
            //   component: 'TimelineSequence',
            //   hidden: false,
            //   permissions: 'all',
            // },
            {
              path: 'timeline-list',
              text: 'Lista detallada',
              icon: 'tasks',
              component: 'ListSequence',
              hidden: false,
              permissions: 'all',
            },
          ],
        },
        {
          path: 'nested',
          text: 'Nesteo',
          directory: 'nested',
          iconSource: 'fas',
          icon: 'calendar-day',
          routes: [
            // TODO: this timeline is hidden until the overlap issue is solved.
            // {
            //   path: 'timeline-sequence',
            //   text: 'Cronograma',
            //   icon: 'stream',
            //   component: 'TimelineSequence',
            //   hidden: false,
            //   permissions: 'all',
            // },
            {
              path: 'timeline-list',
              text: 'Lista detallada',
              icon: 'tasks',
              component: 'ListSequence',
              hidden: false,
              permissions: 'all',
            },
          ],
        },
      ],
    },
    {
      path: 'order',
      title: 'Órdenes',
      directory: 'orders',
      routes: [
        {
          path: 'sales-orders',
          text: 'Órdenes de Venta',
          iconSource: 'fas',
          icon: 'money-check-alt',
          component: 'SalesOrders',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'manual-sales-orders',
          text: 'Pedidos Manuales',
          iconSource: 'fas',
          icon: 'money-check',
          component: 'EditableSalesOrders',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'rush-orders',
          text: 'Órdenes Urgentes',
          iconSource: 'fas',
          icon: 'money-check',
          component: 'RushOrders',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'forecast-orders',
          text: 'Órdenes Previsionales',
          iconSource: 'fas',
          icon: 'dolly-flatbed',
          component: 'ForecastOrders',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'production',
      title: 'Producción',
      directory: 'production',
      dropdowns: [],
      routes: [
        {
          path: 'generate',
          text: 'Programar secuencia',
          iconSource: 'fas',
          icon: 'calendar-star',
          component: 'PlanningSequence',
          sidebarDefault: 'expanded',
        },
      ],
    },
    {
      path: 'mps',
      title: 'MPS',
      directory: 'mps',
      routes: [
        {
          path: 'generate-requirements',
          text: 'Requerimientos de Producción',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'ProductionRequirement',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'generate-nested-requirements',
          text: 'Órdenes de Nesteo',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'NestedProductionRequirement',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'fulfillment',
          text: 'Cumplimiento',
          icon: 'clipboard-check',
          component: 'Fulfillment',
        },
      ],
      dropdowns: [
        {
          path: 'replenishment',
          text: 'Reabastecimiento',
          directory: 'replenishment',
          iconSource: 'fas',
          icon: 'dolly-flatbed-alt',
          routes: [
            {
              path: 'requests',
              text: 'Solicitudes',
              icon: 'tasks',
              component: 'Requests',
              hidden: false,
              permissions: 'all',
            },
            {
              path: 'requirements',
              text: 'Requerimientos',
              icon: 'tasks',
              component: 'Requirements',
              hidden: false,
              permissions: 'all',
            },
            {
              path: 'orders',
              text: 'Órdenes',
              icon: 'dolly-flatbed',
              component: 'Orders',
              hidden: true,
              permissions: 'all',
            },
          ],
        },
      ],
    },
    {
      path: 'inventory',
      title: 'Inventarios',
      directory: 'inventory',
      routes: [
        {
          path: 'lots',
          text: 'Inventario de Lotes',
          iconSource: 'fas',
          icon: 'warehouse-alt',
          component: 'LotsInventory',
          sidebarDefault: 'expanded',
          hidden: true,
        },
        {
          path: 'locked-lots',
          text: 'Lotes Bloqueados',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'LockedInventory',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
      ],
      dropdowns: [
        {
          path: 'warehouses',
          text: 'Almacenes',
          directory: 'warehouses',
          iconSource: 'fas',
          icon: 'warehouse',
          routes: [
            {
              path: 'finished-products',
              text: 'Productos Terminados',
              iconSource: 'fas',
              icon: 'industry',
              component: 'FinishedProducts',
            },
            {
              path: 'finished-products-cutting',
              text: 'PT por Cortar',
              iconSource: 'fas',
              icon: 'industry',
              component: 'CuttingFinishedProducts',
            },
            {
              path: 'wip',
              text: 'WIP',
              iconSource: 'fas',
              icon: 'industry',
              component: 'WIP',
            },
            {
              path: 'wip-welding',
              text: 'Soldadura',
              iconSource: 'fas',
              icon: 'industry',
              component: 'WeldingWIP',
            },
            {
              path: 'nested',
              text: 'Nesteo',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Nested',
            },
            {
              path: 'mills',
              text: 'Molinos',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Mills',
            },
            {
              path: 'external-buys',
              text: 'Compras Externas',
              iconSource: 'fas',
              icon: 'industry',
              component: 'ExternalBuys',
            },
            {
              path: 'reworks',
              text: 'Retrabajos',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Reworks',
            },
            {
              path: 'quality',
              text: 'Calidad',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Quality',
            },
            {
              path: 'dnp',
              text: 'DNP',
              iconSource: 'fas',
              icon: 'industry',
              component: 'DNP',
            },
            {
              path: 'scrap',
              text: 'Scrap',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Scrap',
            },
            {
              path: 'fixtures',
              text: 'Fixtures',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Fixtures',
            },
            {
              path: 'toolings',
              text: 'Toolings',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Toolings',
            },
            {
              path: 'spare-parts',
              text: 'Refacciones',
              iconSource: 'fas',
              icon: 'industry',
              component: 'SpareParts',
            },
            {
              path: 'reparables',
              text: 'Reparables',
              iconSource: 'fas',
              icon: 'industry',
              component: 'Reparables',
            },
          ],
        },
      ],
    },
    {
      path: 'maintenance',
      title: 'Mantenimiento',
      directory: 'maintenance',
      routes: [
        {
          path: 'shifts',
          text: 'Turnos',
          iconSource: 'fas',
          icon: 'user-hard-hat',
          component: 'Shifts',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'shop-floor',
          text: 'Monitor de piso',
          iconSource: 'fas',
          icon: 'tools',
          component: 'ShopFloorMonitor',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'shifts-manage',
          component: 'ManageReasonsShifts',
          exactUrl: false,
          permissions: 'all',
          text: 'Administrar motivos paros',
          hidden: true,
        },
        {
          path: 'shop-floor-manage',
          component: 'ManageReasonsFloorMonitor',
          exactUrl: false,
          permissions: 'all',
          text: 'Administrar motivos paros',
          hidden: true,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'setup',
      title: 'Configuración',
      directory: 'setup',
      routes: [
        {
          path: 'user-manual',
          text: 'Manual de uso',
          component: 'ManualScreen',
          permissions: 'all',
          iconSource: 'fas',
          icon: 'book',
        },
      ],
      dropdowns: [
        {
          path: 'operation',
          text: 'Datos Operativos',
          directory: 'operation',
          icon: 'industry-alt',
          iconSource: 'fas',
          routes: [
            {
              path: 'processes',
              text: 'Procesos',
              component: 'Processes',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'customers',
              text: 'Clientes',
              component: 'Customers',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'warehouses',
              text: 'Almacenes',
              component: 'Warehouses',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'workstations',
              text: 'Estaciones de Trabajo',
              component: 'WorkStations',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'facilities',
              text: 'Plantas',
              component: 'Facilities',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'customer-types',
              text: 'Catálogo de Clientes',
              component: 'CustomerCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'material-types',
              text: 'Catálogo de Materiales',
              component: 'MaterialCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'materials',
              text: 'Materiales',
              component: 'Materials',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'materials-by-process',
              text: 'SKU por Procesos',
              component: 'MaterialsByProcess',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'supplier-types',
              text: 'Catálogo de Proveedores',
              component: 'SupplierCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'materials-per-supplier',
              text: 'Materiales por Proveedor',
              component: 'MaterialsPerSupplier',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'suppliers',
              text: 'Proveedores',
              component: 'Suppliers',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'material-recipes',
              text: 'Recetas de Materiales',
              component: 'MaterialRecipes',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'recipes',
              text: 'Recetas',
              component: 'Recipes',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'changeovers',
              text: 'Changeovers',
              component: 'Changeovers',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          path: 'connectivity',
          text: 'Conectividad',
          directory: 'connectivity',
          icon: 'link',
          iconSource: 'fas',
          routes: [
            {
              path: 'properties',
              text: 'Propiedades',
              component: 'Properties',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'property-categories',
              text: 'Categorías',
              component: 'Categories',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'units-of-measurement',
              text: 'Unidades',
              component: 'Units',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'currencies',
              text: 'Monedas',
              component: 'Currencies',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'monitors',
              text: 'Monitores',
              component: 'Monitors',
              permissions: 'all',
              exactUrl: true,
              hidden: false,
            },
          ],
        },
        {
          path: 'admin',
          text: 'Administración',
          directory: 'admin',
          icon: 'user-shield',
          iconSource: 'fas',
          permissions: ['Valiot Support', 'Admin'],
          routes: [
            {
              path: 'users',
              text: 'Usuarios',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'user-groups',
              text: 'Grupos',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'facility-allocation',
              text: 'Asignación a Plantas',
              iconSource: 'fas',
              icon: 'industry',
              component: 'TenancyAdmin',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
